@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;800;900&display=swap');
/* You can add global styles to this file, and also import other style files */

html, body {
  overflow-x: hidden;
  @apply bg-gray-50;
  height: 100%;
  width: 100%;
}

html, body, * {
  font-family: 'Inter', sans-serif;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #edf2f7;
}

::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 100vh;
  border: 3px solid #edf2f7;
}

::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}

.cc-btn.cc-deny {
  display: none;
}

.cc-banner.cc-bottom {
  text-align: center;
  margin: auto;
}

.cc-btn.cc-allow {
  width: 15em;
}
.cc-message {
  .cc-link {
    display: none;
  }
}


